import React from 'react'
import CenterContent from './centerContent';

const PageNotFound = () => {
  return (
    <CenterContent>
      Page Not Found
    </CenterContent>
  )
}

export default PageNotFound;